.cl_addCustomer {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 172px;
  height: 48px;
  z-index: 100;
}

.cl_radioSetting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.cl_gear {
  color: #ddd;
  font-size: 18px;

  &:hover {
    color: @secondary-color !important;
  }
}

.cl_btnSetting {
  background-color: transparent !important;
  border: transparent !important;
  margin-top: -10px;

  &:hover {
    color: @secondary-color !important;
    border: 0 !important;
    background-color: transparent !important;
  }
}

.btn-flex {
  display: flex;
  gap: 8px;
  font-size: 14px;
  line-height: 28px;
  color: @primary-color;
}

.cl_rowPagination {
  width: 100%;
  margin-top: 20px;
}

.cl_total {
  width: 100%;
  margin-top: 18px;
  color: #fff;
  font-size: 12px;
  text-align: left;
}

.cl_btnSearch {
  margin-left: 8px;
  width: calc(100% - 6px);
}

.ant-radio-wrapper {
  color: #eee !important;
}

.ant-radio-inner {
  border-color: @secondary-color !important;
}

.ant-radio-inner::after {
  background-color: @secondary-color !important;
}

@primary-color: #4A4E69;@secondary-color: #B19499;