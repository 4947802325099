.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 85px;
}

.calendar-top-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 85px;
  @media only screen and (max-width: 768px) {
    overflow-x: auto;
  }
}

.calendar-select,
.hamburger-menu {
  width: 94px;
}

.cursor-pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.datepicker-calendar {
  padding: 0 !important;
  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-picker-cell {
    justify-content: center;
    text-align: center;
  }

  .ant-picker-content {
    thead {
      height: 40px;
      text-transform: uppercase;

      tr th {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: #b2b2b2;
        opacity: 0.8;
        height: 20px;
      }
    }

    .ant-picker-cell-selected {
      .calendar-date {
        background: #c1a9ad;
        border-radius: 50%;
      }
    }
    .calendar-date {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      width: 38px;
      height: 38px;
      margin: auto;
    }

    .ant-picker-cell-in-view {
      color: #4a4e69;
    }

    .ant-picker-cell-disabled {
      color: rgb(0 0 0 / 41%);
    }

    .calendar-leave {
      color: red;
    }
  }

  .ant-picker-cell-today {
    .calendar-date {
      background-color: #e3e3e3;
      border-radius: 50%;
    }
  }

  .calendar-header {
    background-color: white;
    padding: 10px 6px;
  }

  .calendar-arrow {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.119291);
    transform: matrix(-1, 0, 0, 1, 0, 0);

    &:hover {
      border: 2px solid #b19499;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #333;
    text-align: center;

    margin: 0;
    padding: 0;
    font-size: 14px;
    background-color: #ffffff;
    svg {
      color: #b19499;
    }
  }

  .arrow-left {
    position: absolute;
    top: 50%;
    left: -10px;
  }

  .arrow-right {
    position: absolute;
    top: 50%;
    right: -10px;
  }

  .ant-picker-cell-inner.ant-picker-calendar-date {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .ant-picker-calendar-header {
    display: flex;
    justify-content: flex-start !important;
    padding: 0px 0 12px !important;
  }
}

.calendar-top-header {
  border-bottom: 1px solid #aaa;

  .calendar-view-dropdown {
    padding: 4px 25%;
    border: 1px solid #ccc8bc;
    border-radius: 5px;
  }

  > .menu-items > div {
    border-right: 1px solid #e3e3e3;
    flex: 1;
  }

  .day {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #b19499;
  }

  .time {
    font-size: 20px;
  }

  .container-row {
    border-right: 1px solid #e3e3e3;
    height: 100%;

    @media only screen and (max-width: 768px) {
      padding: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }

  .menu-icon {
    position: relative;
    font-size: 20px;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0px !important;
    color: @primary-color !important;

    &:hover {
      color: @secondary-color !important;
      background-color: #efeaeb !important;
    }
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;