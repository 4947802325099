@import './../../styles/_fe_util';
.reservation-page {
  .wrapperScroll {
    position: absolute;
    left: 0;
    top: 60px;
    height: calc(100% - 60px); // 60 header
    width: 100%;
    overflow: auto;
  }

  .text-dark {
    color: #000000;
  }

  .profile-title {
    margin: 0px 0 20px 0;
    width: 100%;
    font-weight: 700;
    color: @primary-color;
    font-size: 32px;
    text-align: center;
  }

  .total {
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
  }

  .font-weight-bold {
    font-weight: 700;
  }

  .reservation-card {
    max-width: 650px;
    margin: auto;
    margin-bottom: 1.5rem;
    border-radius: 6px;
    box-shadow: 0px 2px 8px #bbb;

    .card-badge {
      position: absolute;
      left: 20px;
      top: -2px;
      padding: 2px 12px 2px 12px;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      color: #555;
    }
  }

  .arial {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }
}

.cancelDecoration {
  cursor: pointer;
}

.cancelDecoration:hover {
  text-decoration: underline;
}

.modal-button {
  background-color: @secondary-color !important;
  color: #fff !important;
  border: 0;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;

  &:hover {
    color: #fff !important;
    background-color: darken(@secondary-color, 20%) !important;
  }
}

.round-button {
  border-radius: 20px;
  &:hover {
    color: @secondary-color !important;
    border-color: @secondary-color !important;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;