.ant-input.custom-input {
  border: none !important;
  border-radius: 0 !important; /* Remove border radius */
  outline: none !important;
  background-color: transparent !important;
}

.ant-input.custom-input:focus {
  box-shadow: none !important; /* Remove default box-shadow */
}
@primary-color: #4A4E69;@secondary-color: #B19499;