.buttonBook {
  position: absolute;
  bottom: 12px;
  right: 16px;
  background-color: @primary-color !important;
  color: #fff !important;
  border: 0;

  &:hover {
    background-color: lighten(@primary-color, 20%) !important;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;