.timeline {
  .time {
    max-width: 120px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.5px;
    text-transform: uppercase;

    color: #4a4e69;
  }

  .detail {
    margin-left: 40px;
    min-width: 220px;
  }

  .detail-wrapper {
    .ant-timeline-item-content {
      display: flex;
      width: 100%;
      display: flex;
    }
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;