.cl_iconCard {
  font-size: 28px;
  color: #aaa;
  margin-top: 4px;
}

.cl_valueCard {
  font-size: 36px;
  color: #000;
  margin-top: 16px;
}

.cl_valueError {
  font-size: 18px;
  color: #faa;
  margin-top: 180px;
}

.cl_valueCardService {
  font-size: 24px;
  color: #000;
  margin-top: 10px;
}

.cl_valueCardServiceSub {
  font-size: 16px;
  color: #555;
}

.cl_wrapTabStaffs {
  width: 100%;
  height: 200px;
  overflow: auto;
}

.cl_tabStaffs {
  width: 100%;
  border-spacing: 2px;
  border-collapse: separate;
}

.cl_tabStaffs thead tr {
  background-color: #999;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.cl_tabStaffs tbody tr:nth-child(even) {
  background-color: #eee;
}
.cl_tabStaffs tbody tr td {
  text-align: center;
  font-size: 13px;
}

.cl_euro {
  font-size: 16px;
  color: #888;
}

.cl_freq_euro {
  font-size: 18px;
  color: #000;
}

.cl_notFound {
  font-size: 16px;
  color: #a44;
  margin-top: 20px;
}

@primary-color: #4A4E69;@secondary-color: #B19499;