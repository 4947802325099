.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  height: 100vh;
}

.ant-input.custom-input {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important; /* Customize color */
  border-radius: 0 !important; /* Remove border radius */
  outline: none !important;
}

.ant-input.custom-input:focus {
  border-bottom: 2px solid #1890ff !important; /* Customize focus color */
  box-shadow: none !important; /* Remove default box-shadow */
}

.ant-input-password.custom-input {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important; /* Customize color */
  border-radius: 0 !important; /* Remove border radius */
  outline: none !important;
}

.ant-input-password.custom-input:focus {
  border-bottom: 2px solid #1890ff !important; /* Customize focus color */
  box-shadow: none !important; /* Remove default box-shadow */
}

.ant-input-password-icon {
  color: #d9d9d9; /* Customize icon color */
}

.ant-input-password-icon:hover {
  color: #1890ff; /* Customize icon hover color */
}

.login-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: '200px';
}

.logo {
  max-width: 100px;
  margin-bottom: 1rem;
}

.logo-container {
  margin-bottom: 2rem; /* Add margin to the bottom of the logo */
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.select-selector span svg {
  color: #444 !important;
}

@primary-color: #4A4E69;@secondary-color: #B19499;