.standalone-calendar {
  .ant-picker-range-arrow {
    opacity: 0 !important;
  }

  .calendar-date-value {
    min-width: 36px;
    height: 36px;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .selection-start,
  .selection-end {
    border: 2px solid #b19499;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    transition: border 0.8s;
  }

  .selecting {
    background-color: #e8dfe0;
    transition: background-color 0.8s ease-in-out;
  }

  .calendar-date {
    display: flex;
    align-items: center;
    padding: 8px;
  }

  .calendar-prev {
    position: absolute;
    left: -60px;
    top: 48%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar-next {
    position: absolute;
    right: -60px;
    top: 48%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;