.time-modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;

  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #4a4e69;
}

.time-modal-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;

  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #4a4e69;
}

.time-modal-textarea {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */

  letter-spacing: 0.5px;

  color: #4a4e69;
}

.time-modal-btn {
  height: 41px;
  left: 457px;
  top: 532px;
  width: 173px;
  background: #b19499;
}

@primary-color: #4A4E69;@secondary-color: #B19499;