@import './../../../styles/_fe_util';

.wrapperDateTime {
  margin: 0 10px;
  width: calc(100% - 20px);
  font-weight: 400;
}

.imgCalendar {
  margin: auto;
}

.monthTitle {
  margin: 10px 0 20px 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.rangeForm {
  font-size: 20px;
  font-weight: 700;
}

.rangeTo {
  font-size: 18px;
  font-weight: 400;
}

.selectTime {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 10px 0;
}

.listRangeTime {
  font-size: 16px;
  font-weight: 700;
  margin: 24px 40px 24px 40px;
}

.nodataDate {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}

.select-time-calendar {
  position: relative;

  .calendar-table {
    width: calc(100% - 72px);
    margin: auto;
  }

  .change-date {
    position: absolute;
    top: 50%;
    top: calc(50% - 12px);
    background-color: @secondary-color;
    color: white;
    border: 0 !important;
  }

  button {
    cursor: pointer;
  }

  .prev-date {
    left: 0;
    top: 10px;
  }

  .next-date {
    right: 0;
    top: 10px;
  }

  .date {
    width: 38px;
    height: 38px;
    padding: 3px;
    margin: 0% auto;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .selected-date {
    color: white;
    border-radius: 50%;
    background: @secondary-color;
  }
}

.timelist {
  list-style-type: none;
  padding: 0;

  li {
    border-bottom: 1px solid #dde4e4;
    padding-left: 10px;
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }

    &.active {
      box-sizing: border-box;
      border: 2px solid @secondary-color;
      border-radius: 9px;
      background-color: lighten(@secondary-color, 30%);
    }
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;