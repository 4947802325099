@import './../../styles/_fe_util';

.user-profile-page {
  font-family: 'Arial';
  font-style: normal;

  .wrapperScroll {
    position: absolute;
    left: 0;
    top: 60px;
    height: calc(100% - 60px); // 60 header
    width: 100%;
    overflow: auto;
  }

  .profile-title {
    margin-top: 20px;
    width: 100%;
    font-weight: 700;
    color: @primary-color;
    letter-spacing: 1px;
    font-size: 32px;
    text-align: center;
  }

  .profile-fullname {
    margin-top: 10px;
    font-weight: 700;
    font-size: 22px;
  }

  .card-profile {
    background-color: lighten(@primary-color, 50%);
    border-radius: 9px;
    border: 0;
  }

  .btnUpdate {
    margin-top: 10px;
    background-color: @primary-color;
    color: white;
    border: 0;

    &:hover {
      background-color: lighten(@primary-color, 25%);
      border: 0;
    }

    &:active {
      background-color: lighten(@primary-color, 10%);
    }
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-input {
    font-style: normal;
    border-radius: 5px;
    border: 0 !important;

    &:focus {
      outline: none;
      box-shadow: 1px 1px 6px lighten(@primary-color, 20%);
    }
  }

  .ant-input-affix-wrapper {
    border: 0 !important;
    border-radius: 5px;

    .ant-input {
      &:focus {
        border: none;
      }
    }
  }

  .ant-input-affix-wrapper input {
    border: 0 !important;
  }

  #gender {
    height: 40px;
    width: 86px;

    &.ant-switch:not(.ant-switch-checked) {
      background-color: #f68ce8;
    }

    &.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 18px - 16px);
    }

    .ant-switch-handle {
      position: absolute;
      top: 4px;
      left: 2px;
      width: 32px;
      height: 32px;
      transition: all 0.2s ease-in-out;
    }
  }
}

.modal-button {
  background-color: @secondary-color !important;
  color: #fff !important;
  border: 0;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;

  &:hover {
    color: #fff !important;
    background-color: darken(@secondary-color, 20%) !important;
  }
}

.round-button {
  border-radius: 20px;
  &:hover {
    color: @secondary-color !important;
    border-color: @secondary-color !important;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;