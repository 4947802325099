@import './_fe_variable';

.primary-button {
  background-color: @primary-button-color!important;
  color: #fff !important;
  border-radius: 20px !important;
  padding: 0 40px;

  &:hover {
    cursor: pointer;
    background-color: lighten(@primary-button-color, 20%) !important;
    color: white !important;
  }
}

.secondary-button {
  background-color: @secondary-button-color!important;
  color: #fff !important;
  border-radius: 20px !important;
  border: 0 !important;
  padding: 0 40px;

  &:hover {
    cursor: pointer;
    background-color: darken(@secondary-button-color, 20%) !important;
    color: white !important;
  }
}

.back-icon {
  color: #555 !important;
  border-bottom: 1px solid transparent !important;

  &:hover {
    color: #333 !important;
    border-bottom: 1px solid #e3e3e3 !important;
  }
}

.bg-light-gray {
  background-color: #e3e3e3;
}

.primary-text-colour {
  color: @primary-color;
}

.secondary-text-colour {
  color: @secondary-color;
}

.nav-links {
  color: @primary-color !important;
  font-size: 18px;

  &:hover {
    color: darken(@secondary-color, 10%) !important;
    transition: color 0.5s;
    text-shadow: 2px 3px 16px @secondary-color;
  }
}
