.weekly-calendar {
    .calendar-cell-background {
        border-right: 1px solid #e3e3e3;
    }

    .calendar-hours-container {
        border-right: 1px solid #CCC8BC;
    }

}



@primary-color: #4A4E69;@secondary-color: #B19499;