.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.main-page {
  .front-page-header {
    // border-bottom: 1px solid #aaa;
    background-color: @primary-color;
    color: #fff;
    height: 60px;

    .ml-20 {
      margin-left: 20px;
    }
  }

  .mr-20 {
    margin-right: 20px;
  }

  .front-page-right-header {
    position: absolute;
    width: 100%;
    height: 60px;
    right: 0;
    top: 0;

    .cursor-pointer {
      cursor: pointer;
    }
    .flex-row-between {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 60px;
    }

    .flex-row-end {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: 60px;
    }

    .nav-dropdown {
      .nav-icon {
        position: relative;
        width: 60px;
        right: 0;
        border: 0;
        color: #fff !important;
        background-color: transparent !important;

        &:hover {
          color: @secondary-color !important;
        }
      }
    }
  }
  .page-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
  }
}

.ant-drawer-title,
.ant-drawer-close {
  color: #fff !important;
}

.iconMenu {
  font-size: 24px;
  color: @secondary-color;
  padding-right: 12px;
  margin-top: 16px;
}

.btnGhost {
  border: 0 !important;
}

.language-select {
  width: auto;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 18px;
  }
}

.language-select span svg {
  color: #fff;
}

@primary-color: #4A4E69;@secondary-color: #B19499;