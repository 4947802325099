@import './../../styles/_fe_util';

.hairdresser-container {
  text-align: center;
}

.hairdresser-header {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hairdresser-title {
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 0 10px 0 10px;
  text-align: center;
}

.hairdresser-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  max-width: 350px;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.hairdresser-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 40px;
  padding: 20px;
}

@media (max-width: 1200px) {
  .hairdresser-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .hairdresser-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .hairdresser-list {
    grid-template-columns: 1fr;
  }
}

.hairdresser-info {
  padding: 0px;
  height: 150px;
  overflow: hidden;
  position: relative;
  border-radius: 5%;
}

.hairdresser-info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.hairdresser-subtitle {
  font-size: 1rem;
  color: #888;
}

.delete-button {
  border: none;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  background-color: #b19499;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-button img {
  width: 15px;
  height: 15px;
}

.hairdresser-empty {
  font-size: 1.5rem;
  color: gray;
  margin-top: 20px;
}

@primary-color: #4A4E69;@secondary-color: #B19499;