@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './styles/index.less';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,400&display=swap');

@light-yellow-grey: #ededf0;
@light-grey: #ededed;

.body {
  font-family: @baseFont;
}

.bg-light-yellow {
  background-color: @light-yellow-grey;
  .ant-modal-body {
    background-color: @light-yellow-grey;
  }
}

.text-right {
  text-align: right;
}

.montserrat {
  font-family: 'Montserrat';
  font-style: normal;
}

.arial {
  font-family: 'Arial';
  font-style: normal;
}

.searchbar {
  justify-content: center;

  .search {
    width: 215px;
    height: 35px;
  }

  input {
    border: 0;
    font-family: @baseFont;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875em;
    line-height: 16px;
    letter-spacing: 0.4375px;
  }

  input:focus {
    border: 0;
    box-shadow: unset;
  }
}

* {
  font-family: @baseFont;
}

p {
  margin: 0;
}

.theme-black-text {
  color: #4a4e69;
}

.ant-list-item {
  border-color: #d8cacc;
}

.text-hightlighted {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;

  color: #4a4e69;
}

.light-primary-bg {
  background-color: @light-yellow-grey;
}

.hover-highlight:hover {
  filter: grayscale(100%) sepia(100%);
}

.text-primary-theme {
  color: #d8cacc;

  &:hover {
    color: #c1a9ad;
  }
}

// Start Anagrafica clienti
.profile {
  background-color: white;

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tabpane {
    background: @light-yellow-grey;
  }

  .profile-header {
    padding: 20px 0;
  }

  .bg-light-yellow {
    background: @light-yellow-grey;
  }

  .username {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
  }

  .ant-tabs-nav {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;
    /* or 250% */

    text-align: center;
    letter-spacing: 0.428571px;
    text-transform: uppercase;

    color: #4a4e69;
  }

  .ant-tabs-nav-list {
    border-bottom-color: #4a4e69;
  }

  .ant-tabs-tab-btn {
    &:focus,
    &:active {
      color: #4a4e69;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4a4e69;
    border-bottom-color: #4a4e69;
  }
}

.bg-tab-white {
  .ant-tabs-nav {
    background: #fff;
  }

  .ant-tabs-nav-wrap {
    margin-left: 20px;
  }

  .ant-tabs-ink-bar {
    background: #4a4e69;
  }
}

// END Anafrafica clienti

.sidebar {
  .ant-menu-vertical {
    border-right: 0;
  }

  .space-top {
    padding: 16px 0;
  }
}

.space--top-1 {
  padding: 4px 0;
}

.space--top-2 {
  padding: 8px 0;
}

.space--top-3 {
  padding: 12px 0;
}

// Shop Time Page
.shop-time-lists {
  .list-border-dark {
    border-bottom-color: #efeaeb !important;

    &:last-child {
      border-bottom: none !important;
    }
  }
}

// Staff page
.staff-list {
  .ant-list-item-meta {
    align-items: center;

    .ant-list-item-meta-title {
      color: white;
      margin: 0;
    }

    .ant-list-item-meta-content {
      text-align: left;
    }
  }
}

.client-list {
  .ant-list-item-meta {
    align-items: center;

    .ant-list-item-meta-title {
      color: white;
      margin: 0;
    }
  }
}

.antd-menu-no-border {
  border-right: 0;
}

// Service page

.color-selected {
  border: 2px solid transparent;
  border-radius: 50%;
}

.color-selected.active {
  border: 2px solid #3535b1;
}

.service-list {
  .ant-list-item-meta {
    align-items: center;

    .ant-list-item-meta-avatar {
      margin-right: 8px;
    }

    .ant-list-item-meta-title {
      color: white;
      margin: 0;
    }
  }

  .service-duration {
    font-style: normal;
    font-weight: bold;
    line-height: 56px;

    text-align: right;
    color: #d8cacc;
  }
}

// Calendar
.list {
  padding: 0;
  border-bottom: 1px solid #f1eadc;
  cursor: pointer;
  text-align: center;

  &:last-child {
    border-bottom: 0;
  }
}

.rounded-button {
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.colors {
  width: 28px;
  height: 28px;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 2px 1px #000000;
  border-radius: 50px;
}

.service-staff {
  .ant-col:first-child {
    width: 40%;
  }
}

@keyframes transitionin {
  from {
    transform: translate(0px, -20vh);
    opacity: 0;
  }

  to {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

/* The element to apply the animation to */
.appear-effect {
  animation-name: transitionin;
  animation-duration: 0.6s;
}

// background color
.bg-light {
  background-color: #fff;
}

.shoptime-title {
  background-color: #fff;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
}

// Profile page
#profile-page .ant-upload.ant-upload-select {
  position: relative;
}

// Frontend Auth
.bg-light-grey {
  background-color: @light-grey;
}

.rounded-input,
.ant-input-affix-wrapper {
  background: #ffffff;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;

  &:hover {
    border-color: lighten(@secondary-color, 20%) !important;
  }

  &:focus {
    border-color: @secondary-color !important;
    box-shadow: 2px 2px 6px lighten(@secondary-color, 20%) !important;
  }
}

.truncate {
  width: 96%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.half-hover-opacity {
  border: 0 !important;
  opacity: 0.6;

  &:hover {
    opacity: 1;
    border: 0 !important;
  }
}

.position-relative {
  position: relative;
}

.iconHeader {
  font-size: 24px;
  color: @secondary-color !important;
  padding-right: 12px;
}

.texPrimary {
  color: @primary-color !important;
}

.secondary-button-outline {
  background-color: transparent !important;
  color: @secondary-color !important;
  border-radius: 20px !important;
  border: 1px solid @secondary-color !important;
  padding: 0 20px !important;

  &:hover {
    background: darken(@secondary-color, 20%) !important;
    color: white !important;
  }

  &:not(hover) {
    border: 1px solid @secondary-color !important;
  }
}

.secondary-button-ghost {
  background-color: transparent !important;
  border: 0 !important;

  &:hover {
    color: darken(@secondary-color, 20%) !important;
    border: 0 !important;
  }
}

.secondary-button-ghost-square {
  background-color: transparent !important;
  border: 0 !important;

  &:hover {
    color: darken(@secondary-color, 20%) !important;
    border: 0 !important;
    border-radius: 0px !important;
  }
}

.secondary-button-outline-white {
  background-color: transparent !important;
  color: white !important;
  border-radius: 20px !important;
  border: 1px solid @secondary-color !important;
  padding: 0 20px !important;

  &:hover {
    background: darken(@secondary-color, 20%) !important;
    color: white !important;
  }
}

.ant-switch-checked {
  background-color: @secondary-color !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: lighten(@secondary-color, 15%) !important;
  border-color: @secondary-color !important;
}

.inputBorder {
  border: 1px solid #e3e3e3 !important;

  &:hover {
    border: 1px solid @secondary-color !important;
  }
  &:focus {
    box-shadow: 0 0 6px @secondary-color !important;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #e3e3e3 !important;
  }
}

.ant-input {
  &:hover {
    border: 1px solid @secondary-color !important;
  }
  &:focus {
    box-shadow: 0 0 6px @secondary-color !important;
  }

  &:not(hover) {
    border: 1px solid @secondary-color !important;
  }
}

.ant-input-affix-wrapper {
  border: 0p !important;

  .ant-input {
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
}

.ant-input-affix-wrapper input {
  border: 0 !important;
}

.ant-picker-today-btn {
  color: @secondary-color !important;
}

.ant-picker-focused {
  border-color: @secondary-color !important;
  box-shadow: 1px 1px 6px @secondary-color !important;
}

.ant-btn-primary {
  border-color: @secondary-color !important;
  background: @secondary-color !important;
  border-radius: 20px !important;

  &:hover {
    background: #8e767a !important;
  }
}

.ant-btn-default {
  border-radius: 20px !important;

  &:hover {
    border-color: @secondary-color !important;
    background: #efeaeb !important;
    color: @secondary-color !important;
  }
}

.select-outline {
  border: 1px solid #e3e3e3 !important;

  &:hover {
    border: 1px solid #e3e3e3 !important;
    border-color: @secondary-color !important;
  }

  &:focus {
    border: 1px solid #e3e3e3 !important;
    border-color: @secondary-color !important;
  }
}

.ant-tabs-tab-btn {
  &:hover {
    color: @secondary-color !important;
  }
}

.timtable-text {
  height: 44px;
  margin: 0;
}

.ant-pagination-item {
  border-color: @secondary-color !important;
}

.ant-pagination-item-selected {
  color: @secondary-color !important;
}

.ant-pagination-item a {
  color: #ccc !important;
}

.ant-pagination-item-ellipsis {
  color: #ccc !important;
}

.ant-pagination-item-link-icon {
  color: @secondary-color !important;
}

.ant-pagination-item-link {
  color: #fff !important;
}

.ant-pagination-item-active a {
  color: @primary-color !important;
}

.ant-pagination-item-active {
  background-color: #ddd !important;
}

.ant-menu-title-content {
  color: @primary-color !important;

  &:hover {
    color: @secondary-color !important;
  }
}

.button-ghost {
  background-color: transparent !important;
  border: 0 !important;

  &:hover {
    background-color: transparent !important;
    background: transparent !important;
    border: 0 !important;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;