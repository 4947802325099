@import './../../styles/_fe_util';
.embed-page {
  .round-btn {
    width: auto;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}

.cardEmbed {
  border-radius: 9px;
  border-color: @secondary-color !important;
}

@primary-color: #4A4E69;@secondary-color: #B19499;