@import './../../styles/_fe_util';

.wrapperSlider {
  position: absolute;
  left: 0;
  top: 60px;
  height: calc(100% - 124px); // 60 header + 64 footer
  width: 100%;
  overflow: auto;
}

.contentSlider {
  width: 100%;
  height: max-content;
}

.contentDate {
  font-size: 19px;
  text-align: center;
}

.footerSlider {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: #fff;
  box-sizing: border-box;
  border-top: 1px solid #eee;
}

.footterReview {
  max-width: 140px;
  margin: 11px 0 0 20px;
  line-height: 19px;
}

.slideTitle {
  margin: 16px 20px 0 20px;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}

.serchInput {
  border-radius: 5px;
  border: 1px solid @primary-color !important;
}

.back-button {
  position: absolute;
  left: 20px;
  top: 0;
  width: 40px;
  height: 40px;
  margin-top: 12px;
  background-color: @secondary-color;
  color: #fff !important;
  border: 0;

  &:hover {
    color: #fff;
    background-color: darken(@secondary-color, 20%) !important;
  }

  .centerLeftIco {
    color: white;
    font-size: 14px;
    margin-left: -4px;
  }
}

.continue-button {
  position: absolute;
  right: 20px;
  top: 0;
  width: 100px;
  height: 40px;
  margin-top: 12px;
  background-color: @secondary-color !important;
  color: #fff !important;
  border: 0;

  &:hover {
    color: #fff !important;
    background-color: darken(@secondary-color, 20%) !important;
  }

  &:disabled {
    color: #fff;
    background-color: lighten(@secondary-color, 20%) !important;
  }
}

.book-button {
  position: absolute;
  right: 20px;
  top: 0;
  width: 140px;
  height: 40px;
  margin-top: 12px;
  background-color: @primary-color !important;
  color: #fff !important;
  border: 0;

  &:hover {
    color: #fff !important;
    background-color: lighten(@primary-color, 20%) !important;
  }
}

.classCard {
  position: relative;
  margin: 20px 0 10px 0;
  border-radius: 5px;
  background-color: lighten(@primary-color, 60%);
  width: 100%;
  height: 180px;
  overflow: hidden;
  box-shadow: 2px 2px 6px #aaa;
}

.selected-service {
  background-color: lighten(@secondary-color, 20%);
}

.booked-service {
  background-color: lighten(@primary-color, 50%);
}

.service-list {
  .title {
    color: #767474;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    margin: 10px 10px 0 10px;
  }

  .duration {
    font-weight: lighter;
    font-size: 13px;
  }

  .operator {
    margin-top: 4px;
    height: 36px;
  }

  .price {
    font-weight: normal;
    font-size: 16px;
  }

  .description {
    margin-top: 6px;
    margin-bottom: 14px !important;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contentEndModal {
  text-align: center;
}

.tickicon {
  width: 80px;
  margin: auto;
  color: #85c787;
}

.confirmation-title {
  margin: 10px;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: @primary-color;
}

.link-button {
  padding: 10px 40px;
  font-size: 16px;
  text-align: center;
  background-color: @secondary-color !important;
  color: #fff !important;
  border: 0;
  border-radius: 20px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }

  &:hover {
    color: #fff !important;
    background-color: darken(@secondary-color, 20%) !important;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;