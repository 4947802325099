.btn-padding {
  background-color: @secondary-color !important;
  color: #fff !important;
  padding: 0 40px !important;

  &:hover {
    background: darken(@secondary-color, 20%) !important;
    color: white;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;