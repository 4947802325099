.tableCard {
  width: calc(100% + 4px);
  border-spacing: 2px;
  border-collapse: separate;
  margin: 0 0 10px -2px;

  thead tr th {
    padding: 4px 5px 4px 5px;
    background-color: lighten(@primary-color, 50%); // #d2c2c4; // #b19499;
    font-weight: 400;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    line-height: 18px;
  }

  tbody tr td {
    padding: 7px;
    color: #444;
    text-align: center;
    background-color: #f4f4f4;
  }

  tbody tr td span,
  thead tr th span {
    font: inherit;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;