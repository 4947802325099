.weekly-calendar {
  .calendar-absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
  }

  .position-relative {
    position: relative;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-1 {
    flex: 1;
  }

  // Column

  .calendar-column-cell {
    height: fit-content;
  }

  .column-day {
    border-bottom: 1px solid #fff;
    padding: 6px 0;
  }

  .calendar-cell-background:last-child {
    margin-right: 40px;
    padding-right: 20px;
  }

  // Hour cell
  .calendar-hour {
    border-top: 1px solid #e3e3e3;

    &:first-child {
      border-top: 1px solid #000;
    }
  }

  .agenda-bg-transparent {
    border-color: transparent;
    background: transparent;
  }

  .agenda-hover:hover {
    box-shadow: inset 0 0 2px 2px #3e81e9;
  }

  .calendar-hour-scroll {
    overflow: scroll;
    overflow-y: hidden;
  }

  .sidebar-hour-text {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;

    padding: 0 10px;

    color: #ffffff;
  }

  .sidebar-hour-text-light {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;

    padding: 0 10px;

    color: #ffffff;

    mix-blend-mode: normal;
    opacity: 0.75;
  }

  // Schedule
  .schedule {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    cursor: pointer;

    border: 2px solid transparent;
  }

  .schedule-card {
    margin: 4px;
    background: #489d41;
    padding: 4px;
    height: 112px;
    border-radius: 3px;
  }

  .leave {
    background: #f7f4f5 !important;

    &:hover {
      box-shadow: inset 0 0 2px 2px #f1f1f1;
      cursor: not-allowed;
    }
  }

  .flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;