.weekly-calendar {
  .calendar-column-header {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.928571px;

    color: #4a4e69;
  }

  .calendar-day {
    border-right: 1px solid #ffffff;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;