.register-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 1rem; /* Add padding for small devices */
}

.register-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;
}

.logo {
  max-width: 100px;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

.logo-container {
  margin-bottom: 2rem;
}

.ant-input.custom-input {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important; /* Customize color */
  border-radius: 0 !important; /* Remove border radius */
  outline: none !important;
}

.ant-input.custom-input:focus {
  border-bottom: 2px solid #1890ff !important; /* Customize focus color */
  box-shadow: none !important; /* Remove default box-shadow */
}

.ant-input-password.custom-input {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important; /* Customize color */
  border-radius: 0 !important; /* Remove border radius */
  outline: none !important;
}

.ant-input-password.custom-input:focus {
  border-bottom: 2px solid #1890ff !important; /* Customize focus color */
  box-shadow: none !important; /* Remove default box-shadow */
}

.ant-input-password-icon {
  color: #d9d9d9; /* Customize icon color */
}

.ant-input-password-icon:hover {
  color: #1890ff; /* Customize icon hover color */
}

.ant-form-item-required {
  visibility: hidden;
}

.ant-form-item-required::before {
  display: none;
}

/* Media query for devices with a max width of 600px */
@media (max-width: 600px) {
  .register-form {
    width: 100%;
    padding: 1rem;
  }

  .logo {
    max-width: 80px;
  }

  .logo-container {
    margin-bottom: 1rem;
  }
}

/* Media query for devices with a max width of 400px */
@media (max-width: 400px) {
  .register-form {
    padding: 0.5rem;
  }

  .logo {
    max-width: 60px;
  }

  .logo-container {
    margin-bottom: 0.5rem;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;