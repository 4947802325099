.reject-title {
  line-height: 30px;
}

.reject-text {
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.btn-reason {
  background-color: @primary-color !important;

  &:hover {
    background-color: lighten(@primary-color, 50%) !important;
  }
}

.btn-cancel {
  margin: 20px 0 10px 0;
  background-color: @secondary-color !important;
  font-style: normal;
  text-align: center;
  height: 40px !important;
  color: #fff !important;
  text-transform: uppercase;

  &:hover {
    background: darken(@secondary-color, 20%) !important;
    color: white;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;