.auth-button {
  border-radius: 20px !important;
  width: 100%;
  height: unset;
  padding: 10px 0;
  background-color: @secondary-color !important;
  color: #fff !important;
  border: 0;
  font-size: 16px;

  &:hover {
    transition: color 0.4s;
    color: #fff !important;
    background-color: darken(@secondary-color, 20%) !important;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;