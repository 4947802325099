.iconsMenu {
  font-size: 23px;
  color: @secondary-color;
  padding-right: 12px;
}

.menu-space {
  margin-top: 23px;
}

@primary-color: #4A4E69;@secondary-color: #B19499;