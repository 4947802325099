.bkgHome {
  background: linear-gradient(white 10%, @secondary-color 95%);
}

.btnGo {
  text-align: center;
  padding: 0;
  height: 36px;
  margin-top: 10px;
}

@primary-color: #4A4E69;@secondary-color: #B19499;