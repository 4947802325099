.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.main-page {
  .page-header {
    border-bottom: 1px solid #aaa;
    height: 85px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .sidebar {
    background: #4a4e69;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  .flex-row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 85px;
  }

  .page-title-ag {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 44px;
    color: @primary-color;
  }

  .hamburger-container {
    border-left: 1px solid #d4d3cf;
  }

  .menu-icon {
    position: relative;
    font-size: 20px;
    width: 100%;
    height: 100%;
    border: 0;
    color: @primary-color !important;
    border-radius: 0px !important;

    &:hover {
      color: @secondary-color !important;
      background-color: #efeaeb !important;
    }
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;