.bg-dark-grey {
  background: #b19499 !important;
}

.bg-dark-red {
  background: #fe5a5a !important;
}

.border-right {
  border-right: 1px solid #f1eadc;
}

.agungi-card {
  .ant-card-head-title {
    padding: 0 10px;
  }
}

.close {
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.6;
  cursor: pointer;
  z-index: 1;
  top: 8px;
  left: 0;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #b19499;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.modal-bg {
  .btn:focus {
    box-shadow: 0 0 0 0 !important;
  }

  .ant-modal-header {
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
  }

  .ant-modal-header,
  .ant-modal-content {
    background: #ededf0;
  }

  .ant-modal-header {
    border-radius: 9px 9px 0 0;
  }

  .ant-modal-content {
    mix-blend-mode: normal;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .ant-modal-footer {
    background: #fff;
    border-radius: 0 0 9px 9px;
  }

  .bg-dark-grey.circle-out {
    &:hover {
      box-shadow: inset 0px 0px 0px 2px #a78a38;
    }
  }

  .bg-dark-red.circle-out {
    &:hover {
      box-shadow: inset 0px 0px 0px 2px #d14848;
    }
  }

  .circle-out {
    border-radius: 50%;
    width: 40px;
    padding: 0;
    margin: 0;
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  .btn-schedule-light {
    background-color: @secondary-color !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff !important;
    border-radius: 20px;
  }
}

button.btn.btn-schedule-light:hover {
  background: darken(@secondary-color, 20%) !important;
  color: white;
}

.heading {
  .icon {
    position: relative;
    top: 10px;
    padding: 10px;
  }
}

.service-cards {
  .service-card {
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin: 12px 0;
    .ant-card-body {
      padding: 0;
    }
    .service-card-header {
      border-bottom: 1px solid #333;
    }
  }

  .recurring-typo {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;

    color: #b19499;
  }

  .agungi-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #4a4e69;
  }
}

.border-right-0 {
  border-right: 0;
}

.border-left-0 {
  border-left: 0;
}

.cursor {
  cursor: pointer;
}

.modal-body {
  input:focus {
    box-shadow: 'rgb(213 170 39 / 50%) 0px 0px 0px 1px';
  }
}

.hover-tranform-scale {
  &:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
  }
}

.user-type {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: #b19499;
}

.title-1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  text-transform: uppercase;
}

.badge {
  background: #ffffff;
  border: 1px solid #b19499;
  border-radius: 5px;
  margin: 0 4px;
  padding: 0px 10px;
}

.badge-service {
  .text {
    position: relative;
    top: -2px;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;