*:not(input):not(textarea) {
  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

.calendar {
  .calendar-absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-1 {
    flex: 1;
  }

  // Column
  .calendar-column {
    border-left: 1px solid #000;

    &:last-child {
      border-right: 1px solid #000;
    }
  }

  .calendar-column-cell {
    height: fit-content;
  }

  .overflow-scroll {
    overflow: scroll;
    overflow-x: hidden;
  }

  @media screen and (max-width: 1200px) {
    .overflow-scroll {
      overflow: scroll;
    }
  }

  // Hour cell
  .calendar-hour {
    border-top: 1px solid #b5b5b5;

    &:first-child {
      border-top: 1px solid #000;
    }
  }

  .agenda-bg-transparent {
    border-color: transparent;
    background: transparent;
  }

  .agenda-hover:hover {
    box-shadow: inset 0 0 2px 2px #3e81e9;
  }

  .calendar-hour-scroll {
    overflow: scroll;
    overflow-y: hidden;
  }

  .sidebar-hour-text {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;

    padding: 0 10px;

    color: #ffffff;
  }

  .sidebar-hour-text-light {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;

    padding: 0 10px;

    color: #ffffff;

    mix-blend-mode: normal;
    opacity: 0.75;
  }

  // Schedule
  .schedule {
    position: absolute;
    top: 0;
    width: 100%;
    cursor: pointer;

    border: 3px solid transparent;
    border-top-color: #fff;
    border-bottom-color: #fff;

    &:hover {
      z-index: 100 !important;
      filter: drop-shadow(0px 0px 2px);
    }
  }

  .schedule-card {
    margin: 4px;
    background: #489d41;
    padding: 4px;
    height: 112px;
    border-radius: 3px;
  }

  .leave {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='pattern_31DIm9' patternUnits='userSpaceOnUse' width='9.5' height='9.5' patternTransform='rotate(45)'%3E%3Cline x1='0' y='0' x2='0' y2='9.5' stroke='%23616161' stroke-width='1'%3E%3C/line%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23pattern_31DIm9)' opacity='1'%3E%3C/rect%3E%3C/svg%3E");

    &:hover {
      box-shadow: inset 0 0 1px 2px #333;
      cursor: pointer;
    }
  }

  .flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.hour-line {
  width: 100%;
  height: 2px;
  background: green;
  position: absolute;
  z-index: 1;
}

@primary-color: #4A4E69;@secondary-color: #B19499;