.cl_boxMenu {
  position: absolute;
  width: 150px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #aaa;
  z-index: 1000;
  padding: 2px 2px 3px 2px;
}

.cl_rowTitle {
  font-size: 14px;
  line-height: 18px;
}

.cl_rowSubtitle {
  font-size: 12px;
  line-height: 15px;
  color: #606060;
}

.cl_menuRow {
  width: 100%;
  height: 54px;
  padding: 2px 6px 2px 6px;
  cursor: pointer;
  background-color: #fff;
}

.cl_menuRow:hover {
  background-color: #e0e0e0;
}

@primary-color: #4A4E69;@secondary-color: #B19499;