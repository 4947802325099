.wrapperReview {
  margin-top: 12px;
  width: calc(100% - 40px);
  font-weight: 400;
  font-size: 18px;

  .titleGroup {
    color: @primary-color;
    font-weight: 700;
  }

  .card-review {
    background-color: lighten(@secondary-color, 20%);
    border-radius: 9px;
    border: 0;
    padding: 10px;
    box-shadow: 2px 2px 6px #aaa;
  }

  .imgCalendar {
    width: 72px;
  }

  .textDateFormat {
    margin: 2px 0 2px 0;
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 700;
    color: @primary-color;
  }

  .labelOperator {
    font-size: 13px;
  }

  .operator {
    margin-top: 6px;
    font-size: 16px;
  }

  .totalPrice {
    padding-top: 10px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: @primary-color;
  }
}

@primary-color: #4A4E69;@secondary-color: #B19499;