// stylelint-disable indentation
// Box Spacing Less

@spacer: 1rem;
@spacers: {
  0: 0 !important;
  1: @spacer * 0.25 !important;
  2: @spacer * 0.5 !important;
  3: @spacer !important;
  4: @spacer * 1.5 !important;
  5: @spacer * 3 !important;
};

@alignTypes: {
  start: left;
  center: center;
  end: right;
};

@justifyTypes: {
  start: start;
  center: center;
  end: right;
  between: space-between;
  around: space-around;
  evenly: space-evenly;
};
// Flex justify classes
.generateSpacing(justify-content, @justifyTypes, justify-content);

// Margin
.generateSpacing(mt, @spacers, margin-top);
.generateSpacing(mb, @spacers, margin-bottom);
.generateSpacing(ml, @spacers, margin-left);
.generateSpacing(mr, @spacers, margin-right);
.generateSpacing(mx, @spacers, margin-left margin-right);
.generateSpacing(my, @spacers, margin-top margin-bottom);

// Padding
.generateSpacing(pt, @spacers, padding-top);
.generateSpacing(pb, @spacers, padding-bottom);
.generateSpacing(pl, @spacers, padding-left);
.generateSpacing(pr, @spacers, padding-right);
.generateSpacing(px, @spacers, padding-left padding-right);
.generateSpacing(py, @spacers, padding-top padding-bottom);

.generateSpacing(text, @alignTypes, text-align);

.font-weight-light {
  font-weight: light;
}

// Custom Added Utilities Classes
.flex-1 {
  flex: 1;
}

.vh-100 {
  min-height: 100vh;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.border-0 {
  border: none !important;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.border-left {
  border-left: 1px solid #d4d3cf;
}

.cursor-pointer {
  cursor: pointer;
}

.border-transparent {
  border-color: transparent !important;
}

.text-white {
  color: white;
}

.overflow-scroll {
  overflow: scroll;
}

.align-items-center {
  align-items: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-hidden {
  overflow: hidden;
}
.text-center {
  text-align: center;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin: 0 auto;
}

.flat {
  border-radius: 0;
}

.text-bold {
  font-weight: 600;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.text {
  font-style: normal;
  font-size: 0.875em;
  line-height: 16px;
}

.primary-button {
  background-color: @primary-button-color;
  color: white;

  &:hover {
    background-color: #e0bb51;
    color: white;
  }
}

// Weekly schedule title
.dailycard {
  .weekly_date_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    /* or 136% */

    text-align: center;
    letter-spacing: -0.5px;
    color: #4a4e69;
  }

  .weekly-time-card {
    border: 3px solid #d4d3cf;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  }
  .icon-placeholder {
    position: absolute;
    z-index: 1;
    top: -16px;
    width: fit-content;
    margin: auto;
    right: calc(50% - 21px);

    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 3px solid #e3e3e3;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dailycard.selected {
  .weekly-time-card {
    border-color: #b19499;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  }
  .icon-placeholder {
    border-color: #b19499;
    transition: border-color 0.6s;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
